export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const PROJECTS_COUNT = 'PROJECTS_COUNT';
export const REQUEST = 'REQUEST';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const projectV2ApiPrefix = '/api/v2/projects';
export interface Actions {
  type: string;
  data: any;
}
export interface ProjectsState {
  isFetching: boolean;
  projects: ProjectDetails[];
  selectedProject: ProjectDetails;
  projectCount: number;
}
interface ProjectResponse {
  isSuccess: boolean;
  message: string;
  data: unknown;
}
export interface ProjectRequestBody {
  userId: string;
}
export interface ProjectsListReqBody {
  page: number;
  pageSize: number;
}
export type ProjectActionTypes = Actions | ProjectResponse | ProjectsState;

export interface UpdateProjectRequestBody {
  projectId: string;
  projectDetails: object;
}
export interface NewProjectReqBody {
  user: any;
  name: string;
  type: string;
  objective_textual: string;
  objective: string;
  type_of_industries: string;
  members: any;
  ownerId: string;
  description?: string;
}

export interface SearchProjectReqBody {
  text: string;
}

export interface ProjectDetails {
  ansoffQuadrant: string;
  created: string;
  isActive: boolean;
  members: MemberObj[];
  name: string;
  ownerId: { name: string; _id: string };
  project_tags: string[];
  _id: string;
  projectAnchors?: any[];
  scenarios?: number;
  totalSignals?: number;
  description?: string;
  intention?: string;
  industryOfSegments?: string[];
}

export interface MemberObj {
  frequency: number;
  permissionAiAssets: string;
  permissionBusiness: string;
  permissionCustomer: string;
  permissionFinancial: string;
  permissionProduct: string;
  permissionTeam: string;
  userId: {
    botType: string;
    email: string;
    lastSeen: string;
    name: string;
    userType: string;
    _id: string;
  };
}
